// ResidentSearch.js

import React, { useState } from 'react';
import { parse } from 'papaparse';
import './ResidentSearch.css'; // Import the CSS file

function ResidentSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchResults(null);

    if (!searchTerm) {
      return;
    }

    const csvFilePath = process.env.PUBLIC_URL + '/residents.csv';

    fetch(csvFilePath)
      .then((response) => response.text())
      .then((csvString) => {
        parse(csvString, {
          header: true,
          complete: (results) => {
            const residents = results.data;
            const filteredResults = residents.filter(
              (resident) => resident['ID number'] === searchTerm
            );
            setSearchResults(filteredResults.length > 0 ? filteredResults : 'Not Voter');
          },
        });
      });
  };

  return (
    <div className="resident-search-container">
      <h2>Resident Search</h2>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Enter ID number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      {searchResults !== null && (
        <div>
          {searchResults === 'Not Voter' ? (
            <p className="no-matching-records">No matching records found. Not a voter.</p>
          ) : (
            <ul className="search-results">
              {searchResults.map((resident) => (
                <li key={resident['ID number']}>
                  {resident['fname']} - {resident['caw']}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default ResidentSearch;
